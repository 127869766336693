<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="search BoxHeight">
      <!-- 搜索框 -->
      <div class="flex mt15 bbox plr13">
        <el-input
          v-model="searchValue"
          :placeholder="$t('searchContent')"
          clearable
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
            @click="emptyInput"
          ></i>
        </el-input>
        <el-button type="primary" @click="onSubmit" class="ml10 pl10 pr10">{{
          $t("search")
        }}</el-button>
      </div>
      <!-- 历史搜索 -->
      <div class="f12 historyBox bbox plr13" v-if="searchValue.length == 0">
        <div class="flex align-center justify-between mt10 mb10">
          <div class="f13 text333">{{ $t("searchHistory") }}</div>
          <div class="text999 f12" @click="delAll">
            <i class="el-icon-delete"></i>
            <span class="pl5">{{ $t("emptySearch") }}</span>
          </div>
        </div>
        <ul class="f13 text999 historyList">
          <li
            class="flex align-center justify-between"
            v-for="record in searchList"
            :key="record.id"
          >
            <span
              class="flex1 text-cut"
              @click="searchHistory(record.keyword)"
              >{{ record.keyword }}</span
            >
            <i class="el-icon-close textred fw700 f14" @click="del(record)"></i>
          </li>
        </ul>
      </div>
      <!-- 搜索结果 -->
      <div class="resultBox" v-else>
        <div class="mt10">
          <div class="tabbar flex align-center f14 text333 bbox plr13">
            <div
              :class="
                isActive == i
                  ? 'flex1 textc textblue fw700'
                  : 'flex1 textc fw700'
              "
              v-for="(item, i) in tabbar"
              :key="item"
              @click="handleTab(i)"
            >
              {{ item }}
            </div>
          </div>
          <!-- 故障代码 -->
          <div class="resultList bbox plr13" v-if="isActive == 0">
            <div v-if="falutList.length > 0">
              <div
                class="resultItem flex align-center"
                v-for="data in falutList"
                :key="data.typeCode"
                @click="toBrowserFile(data.typeCode, data.typeName,data.modelName)"
              >
                <div class="f14 text333 flex1 ml20">
                  {{ data.typeName }}
                </div>
              </div>
            </div>
            <!-- 结果为空 -->
            <div v-else class="kong positiona">
              <img src="../assets/images/kong.png" alt="" width="100" />
              <div class="f12 textc mt15 text999">{{$t("my.noTestLogging")}}</div>
            </div>
          </div>
          <!-- 仪器 -->
          <div class="resultList bbox plr13" v-if="isActive == 1">
            <div v-if="machineList.length > 0 && isActive == 1">
              <div
                class="resultItem flex align-center"
                v-for="machine in machineList"
                :key="machine.typeCode"
                @click="gotoDetail(machine.typeCode, machine.typeName)"
              >
                <div class="pic">
                  <img :src="machine.logo" alt="" />
                </div>
                <div class="f14 text333 flex1 ml20">
                  {{ machine.typeName }}
                </div>
              </div>
            </div>
            <!-- 结果为空 -->
            <div v-else class="kong positiona">
              <img src="../assets/images/kong.png" alt="" width="100" />
              <div class="f12 textc mt15 text999">{{$t("my.noTestLogging")}}</div>
            </div>
          </div>
          <!-- 文档资料 -->
          <div class="resultList bbox plr13" v-if="isActive == 2">
            <div v-if="dataList.length > 0 && isActive == 2">
              <div
                class="resultItem flex align-center"
                v-for="data in dataList"
                :key="data.typeCode"
                @click="toBrowserFile(data.typeCode, data.typeName,data.modelName)"
              >
                <div class="f14 text333 flex1 ml20">
                  {{ data.typeName }}
                </div>
              </div>
            </div>
            <!-- 结果为空 -->
            <div v-else class="kong positiona">
              <img src="../assets/images/kong.png" alt="" width="100" />
              <div class="f12 textc mt15 text999">{{$t("my.noTestLogging")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/commonHead.vue";
import Footer from "../components/footer.vue";
import { Dialog } from "vant";
export default {
  components: { Header, Footer },
  data() {
    return {
      searchValue: "",
      isHide: false,
      tabbar: ["故障手册", "仪器", "资料文档"],
      isActive: 0,
      searchList: [],
      machineList: [],
      dataList: [],
      examList: [],
      falutList: [],
      listFlag: 0,
    };
  },
  methods: {
    emptyInput() {
      this.searchValue = "";
    },
    onSubmit() {
      this.isHide = true;
      this.handleSearch();
    },
    delAll() {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定清空历史搜索吗？",
      }).then(() => {
        var sendData = {};
        sendData.memberCode = localStorage.getItem("memberCode");
        var that = this;
        this.$http
          .post("memberSearchRecords/deleteMemberSearchRecords", sendData)
          .then(function (res) {
            if (200 == res.data.code) {
              that.searchList = [];
            }
          });
      });
    },
    del(record) {
      Dialog.confirm({
        title: "温馨提示",
        message: "确定删除吗？",
      }).then(() => {
        var sendData = {};
        sendData.id = record.id;
        var that = this;
        this.$http
          .post("memberSearchRecords/delete", sendData)
          .then(function (res) {
            if (200 == res.data.code) {
              var index = that.searchList.indexOf(record);
              that.searchList.splice(index, 1);
            }
          });
      });
    },
    handleTab(i) {
      this.isActive = i;
    },
    //查询历史搜索记录
    getHistorySearchRecord() {
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberSearchRecords/queryMemberSearchRecordsList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.searchList = res.data.data;
          }
        });
    },
    //查询搜索结果
    async handleSearch() {
      var memberCode = localStorage.getItem("memberCode");
      var sendData = {};
      sendData.memberCode = memberCode;
      sendData.keyword = this.searchValue;
      var that = this;
      await this.$http
        .post("memberSearchRecords/saveMemberSearchRecords", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.getHistorySearchRecord(memberCode);
          }
        });
      //查询搜索结果
      var sendData = {};
      sendData.memberCode = memberCode;
      sendData.keyword = this.searchValue;
      var that = this;
      this.$http
        .post("memberSearchRecords/searchData", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            console.log("--------------searchData--------------------");
            console.log(res.data.data);
            console.log("--------------searchData--------------------");
            that.dataList = res.data.data.data;
            that.machineList = res.data.data.machine;
            that.falutList = res.data.data.fault;
          }
        });
    },
    searchHistory(keyword) {
      this.searchValue = keyword;
      this.handleSearch();
    },
    gotoDetail(typeCode, typeName) {
      var sendData = {};
      sendData.typeCode = typeCode;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberMachineType/queryMachineStatus", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            if ("1" == res.data.data) {
              that.$router.push({
                path: "/informationDetail",
                query: {
                  typeCode: typeCode,
                  typeName: typeName,
                  key: that.key,
                },
              });
            } else {
              that.$notify.error({
                title: "提示",
                message: "查询资料失败，请先通过当前机型的考试",
                showClose: true,
              });
            }
          }
        });
    },
    toBrowserFile(typeCode, typeName,modelName) {
      var sendData = {};
      sendData.objectCode = typeCode;
      var that = this;
      this.$http.post("annexFile/queryFileList", sendData).then(function (res) {
        if (200 == res.data.code) {
          var file = res.data.data[0];
          if (file != undefined && file.fileUrl != undefined) {
            //是零部件则展示图片
            var url = file.fileUrl;
            var content = file.represent;
            that.$router.push({
              path: "/previewFile",
              query: {
                url: url,
                content: content,
                typeCode: typeCode,
                typeName: typeName,
                machineType: modelName,
              },
            });
            that.$router.push({ path: "/picPreview", query: data });
          } else {
            that.$message("无文件地址,请联系管理员!");
          }
        }
      });
    },
  },
  created() {
    this.getHistorySearchRecord();
  },
};
</script>

<style scoped>
.historyList > li {
  margin: 3px 0;
}
.tabbar {
  height: 40px;
  border-bottom: 1px solid #f5f5f5;
}
.resultItem {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #f5f5f5;
}
.resultItem .pic {
  width: 82px;
  height: 82px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  padding: 5px;
}
.search >>> .el-input__inner {
  line-height: 24px;
}
</style>